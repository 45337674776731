import React from 'react';
import Layout from '../components/Layout';
import Container from '../components/Container';
import { graphql, useStaticQuery } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import Markdown from 'markdown-to-jsx';
import Seo from '../components/Seo';
import { useMediaQuery } from 'react-responsive';

const contentQuery = graphql`
  query {
    markdownRemark {
      html
      tableOfContents
      rawMarkdownBody
    }
  }
`;

const WhyBitnimbus = () => {
  const data = useStaticQuery(contentQuery);

  const getHeadingId = (children: string) => {
    const tokens = children.split(' ');
    const ALLOWED_SPECIAL_CHARACTERS = ['_', '-'];
    const isAllowedCharacter = (ch: string) =>
      (ch >= 'a' && ch <= 'z') ||
      (ch >= '0' && ch <= '9') ||
      ALLOWED_SPECIAL_CHARACTERS.includes(ch);
    const result = tokens.map((token: string) => {
      let alphaNumericToken = '';
      for (let i = 0; i < token.length; i++) {
        const ch = token.charAt(i);
        if (!isAllowedCharacter(ch)) {
          continue;
        }
        alphaNumericToken += ch;
      }
      return alphaNumericToken;
    });
    return result.join('-');
  };

  return (
    <Layout>
      <section className="my-6 mx-8">
        <Container>
          <div>
            <div className="p-4 md:p-12 primary-gradient">
              <div className="space-y-6">
                <h1 className="text-center">
                  Reasons to choose Bitnimbus.io Managed Kafka
                </h1>
                <p className="px-4 md:px-12">
                  At Bitnimbus.io, we've crafted a fully managed Kafka solution
                  that prioritizes performance, transparency, and developer
                  experience. Here's what sets us apart:
                </p>
              </div>
            </div>
            <div className="lg:grid lg:grid-cols-[30%_70%]">
              <div>
                <div className="lg:sticky lg:top-16 lg:m-0 lg:mr-6 border border-gray rounded-lg p-6 max-w-fit mx-auto my-4">
                  <h3 className="text-foreground font-bold">
                    Table of Contents
                  </h3>
                  <div className="markdown mt-4">
                    <Markdown>{data.markdownRemark.tableOfContents}</Markdown>
                  </div>
                </div>
              </div>
              <div className="markdown">
                <ReactMarkdown
                  components={{
                    h2({ children }) {
                      return (
                        <h2
                          id={
                            typeof children === 'string'
                              ? getHeadingId(children.toLowerCase())
                              : children?.toString()
                          }
                          // className="text-[1rem] leading-relaxed md:text-[2rem] md:leading-normal font-bold text-foreground"
                        >
                          {children}
                        </h2>
                      );
                    },
                  }}
                >
                  {data.markdownRemark.rawMarkdownBody}
                </ReactMarkdown>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  );
};

export const Head = () => {
  return (
    <Seo
      title="Why us - Bitnimbus.io"
      canonical="https://bitnimbus.io/why-bitnimbus"
    />
  );
};

export default WhyBitnimbus;
